<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.version.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton color="primary" v-on:click="createTableRow" style="margin-right: 10px;" v-if="checkPermission('create-version')">
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert v-show="alertMessage" :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
            {{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/version/list" :options="options" ref="myTable"></v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {version} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from 'vue-tables-2';
import VueJsonToCsv from "vue-json-to-csv";
import VueSweetalert2 from 'vue-sweetalert2';
import store from "../../store/store";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false)
Vue.use(VueForm, options);
export default {
  name: 'VersionItem',
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      dismissCountDown: 0,
      alertMessage: '',
      messageColor: 'success',
      self: this,
      json_data: [],
      json_label: {},
      json_title: "",
      columns: ['appType', 'forceUpdate','status', 'versionCode'],
      data: [],
      options: {
        headings: {
          appType: this.$lang.version.table.appType,
          forceUpdate: this.$lang.version.table.forceUpdate,
          status: this.$lang.version.table.status,
          versionCode: this.$lang.version.table.versionCode,
        },
        editableColumns: [],
        sortable: [],
        filterable: []
      },
      module: version,
    }
  },
  created() {
    let self = this;
    store.commit('showLoader', false);
    if (localStorage.getItem('notificationMessage') !== "") {
      self.dismissCountDown = 10;
      self.alertMessage = localStorage.getItem('notificationMessage');
      self.messageColor = localStorage.getItem('notificationType');
      localStorage.setItem('notificationMessage', '');
      localStorage.setItem('notificationType', '');
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
  },
  methods: {
    createTableRow() {
      this.$router.push({name: 'Create Version'})
    },
  }
}
</script>
